import Chip from "@mui/material/Chip";
import { ENV_PRODUCTION } from "@recare/core/model/config";
import { activateEnvSwitch } from "@recare/core/model/utils/featureFlags";
import { useEnvContext } from "context/EnvContext";
import { GREY_300 } from "ds/materials/colors";
import styled, { css, keyframes } from "styled-components";

const blinkKeyFrame = keyframes`
0% {
  opacity: 0;
  background-color: red;
}
100% {
  opacity: 1;
  background-color: red;
}
`;

const BlinkingDiv = styled.div<{ env: string }>`
  display: flex;
  align-items: center;
  flex-grow: 1;
  animation: ${(props) =>
    props.env === ENV_PRODUCTION
      ? css`${blinkKeyFrame} 0.25s infinite}`
      : "none"};
`;

export default function BlinkingEnv() {
  const { env } = useEnvContext();

  if (!activateEnvSwitch) return null;

  return (
    <BlinkingDiv env={env}>
      <Chip label={env} sx={{ background: GREY_300 }} />
    </BlinkingDiv>
  );
}
