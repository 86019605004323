import { QUERY_PROGRESS_SUCCEED } from "@recare/core/consts";
import {
  LegalDocumentType,
  LegalDocuments,
  QueryProgress,
} from "@recare/core/types";

import { useLegalDocumentsContext } from "dsl/atoms/LegalDocuments";
import { useEffect } from "react";

function getLegalDocumentsHelper(
  legalDocuments: LegalDocuments | undefined,
  progress: QueryProgress,
) {
  const getDocumentUrl = (type: LegalDocumentType): string => {
    const url = legalDocuments?.[type]?.url;

    if (legalDocuments && !url && progress === QUERY_PROGRESS_SUCCEED) {
      console.error(`url missing in legal document: ${type}`);
    }

    return url ?? "";
  };

  const openDocumentUrl = (type: LegalDocumentType) =>
    window.open(getDocumentUrl(type), "_blank");

  const getDocument = (type: LegalDocumentType) => legalDocuments?.[type];

  return { legalDocuments, getDocumentUrl, openDocumentUrl, getDocument };
}

// This hook uses the context for adding the documents to the local storage to prevent fetching every time
export function useLegalDocuments(props?: {
  careproviderId?: number;
  careseekerId?: number;
}) {
  const { getLegalDocs, progress } = useLegalDocumentsContext();

  return getLegalDocumentsHelper(getLegalDocs(props), progress);
}

// This hook needs to be called whenever the LegalDocumentsContext is not present (e.g. ACP, New Onboarding)
// This will enforce setting the context manually
export const useManualSetLegalDocuments = (props?: {
  careproviderId?: number;
  careseekerId?: number;
}) => {
  const { setLegalDocs } = useLegalDocumentsContext();
  const { careproviderId, careseekerId } = props ?? {};

  useEffect(() => {
    setLegalDocs({
      careseekerId,
      careproviderId,
    });
  }, [careseekerId, careproviderId]);
};
