import { APP_KIND } from "@recare/core/consts";
import { AppType, Env } from "@recare/core/types";
import { ENV_DEMO, ENV_DEVELOPMENT, ENV_PREPROD, ENV_STAGING } from ".";

export const LOGIN_SUGGESTIONS: {
  app: AppType;
  email: string;
  env: Env[];
  label: string;
  password: string;
}[] = [
  // Sender app
  {
    label: "careseeker_staff",
    email: "careseeker_staff",
    password: "test",
    env: [ENV_STAGING, ENV_DEVELOPMENT],
    app: APP_KIND.APP_CLINIC,
  },
  {
    label: "fr_staff",
    email: "fr_staff@gmail.com",
    password: "test",
    env: [ENV_STAGING, ENV_DEVELOPMENT],
    app: APP_KIND.APP_CLINIC,
  },
  {
    label: "testsenderuser",
    email: "testsenderuser@recaresolutions.com",
    password: "6PXA?zE5oL?by8$z",
    env: [ENV_PREPROD, ENV_DEMO],
    app: APP_KIND.APP_CLINIC,
  },
  // Receiver app
  {
    label: "careprovider_staff",
    email: "careprovider_staff",
    password: "test",
    env: [ENV_STAGING, ENV_DEVELOPMENT],
    app: APP_KIND.APP_CAREPROVIDER,
  },
  {
    label: "fr_staff",
    email: "fr_staff@gmail.com",
    password: "test",
    env: [ENV_STAGING, ENV_DEVELOPMENT],
    app: APP_KIND.APP_CAREPROVIDER,
  },
  {
    label: "testpartneruser",
    email: "testpartneruser@recaresolutions.com",
    password: "YXds+)hX8m7S3h]Z",
    env: [ENV_PREPROD, ENV_DEMO],
    app: APP_KIND.APP_CAREPROVIDER,
  },
  {
    label: "testpartneruser2",
    email: "testpartneruser2@recaresolutions.com",
    password: "f!#ttXqjH47RCA4S",
    env: [ENV_PREPROD, ENV_DEMO],
    app: APP_KIND.APP_CAREPROVIDER,
  },
  // ACP
  {
    label: "admin",
    email: "admin@recaresolutions.com",
    password: "Adminstaging1",
    env: [ENV_STAGING, ENV_DEVELOPMENT],
    app: APP_KIND.APP_ACP,
  },
  {
    label: "testadminuser",
    email: "testadminuser@recaresolutions.com",
    password: "#6$#stoa8N7J4zHB",
    env: [ENV_PREPROD, ENV_DEMO],
    app: APP_KIND.APP_ACP,
  },
];
