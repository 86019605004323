import { css, keyframes } from "styled-components";
export const standardCurve = "cubic-bezier(0.4, 0.0, 0.2, 1)";
export const decelerationCurve = "cubic-bezier(0.0, 0.0, 0.2, 1)";
export const accelerationCurve = "cubic-bezier(0.4, 0.0, 1, 1)";
export const sharpCurve = "cubic-bezier(0.4, 0.0, 0.6, 1)";

export function appearanceMovement(
  x: number | string,
  y: number | string,
  { speed = "0.300s" }: { speed: string },
) {
  const inKeyframes = keyframes`
    from {
      transform: translate3d(${x}, ${y}, 0);
      opacity: 0;
    }

    to {
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
  `;

  return css`
    ${inKeyframes} ${speed} ${standardCurve} forwards
  `;
}
