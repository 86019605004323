import { useTranslations } from "@recare/translations";
import { margin } from "ds/materials/metrics";
import { Caption, FONT_SIZE_12 } from "ds/materials/typography";
import { CSSProperties } from "react";

export function AsteriskExplained({
  customMargin,
  style,
}: {
  customMargin?: string;
  style?: CSSProperties;
}) {
  const translations = useTranslations();
  return (
    <Caption
      as="p"
      margin={customMargin ?? margin(1, 0, 0)}
      fontSize={FONT_SIZE_12}
      whiteSpace="normal"
      style={{ alignSelf: "flex-start", ...style }}
    >
      {translations.providersearch.assessment.mandatoryFieldInformation}
    </Caption>
  );
}
